import React, { ReactNode } from "react";
import "./style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";
import "swiper/scss/pagination";
import "swiper/scss/navigation";
import "swiper/scss/effect-fade";
import TitleParagraph from "../../Texts/TitleParagraph";
import Button from "../../Buttons/Button";

import mainLogo from "../../../assets/logos/yacht_charter_main_logo.svg";
// import bannerVideo from "../../../assets/videos/yacht-charter-banner-video.mp4";
// import bannerVideo2 from "../../../assets/videos/yacht-charter-banner-video-2.mp4";
import bannerVideo2 from "../../../assets/videos/video-destaque-Nx400ht.mp4";
// import bannerVideo3 from "../../../assets/videos/yacht_charter_miami-short-banner.mp4";
// import bannerYacht from "../../../assets/videos/yacht_charter_banner.mp4";
import preLoadImage from "../../../assets/images/banner/yacht-charter-banner-pre-load.png";

interface FeaturedCarouselProps {
  imagesCarousel?: {
    id: string;
    img: string;
  }[];
  infoText?: {
    title?: string;
    subTitle?: string;
    buttonText?: string;
    buttonClick?: (a: any) => void;
    secondaryButtonText?: string;
    secondaryButtonClick?: (a: any) => void;
  };
  secondaryButton?: boolean;
  showLogo?: boolean;
  videoBanner?: boolean;
}

const FeaturedCarousel: React.FC<FeaturedCarouselProps> = (props) => {
  const {
    imagesCarousel,
    infoText,
    showLogo = true,
    videoBanner = false,
    secondaryButton = true,
  } = props;

  return (
    <Swiper
      effect="fade"
      centeredSlides={true}
      autoplay={{
        delay: 6000,
        disableOnInteraction: false,
      }}
      //   pagination={{
      //     clickable: true,
      //   }}
      speed={2000}
      navigation={false}
      modules={[Autoplay, Pagination, Navigation, EffectFade]}
      id="featured-carousel"
    >
      {imagesCarousel &&
        imagesCarousel.map((img) => (
          <SwiperSlide key={img.id} className="featured-carousel-container">
            <img src={img.img} alt={img.id} />
          </SwiperSlide>
        ))}
      {videoBanner && (
        <SwiperSlide className="featured-carousel-container">
          <video
            className="video-player-banner"
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={bannerVideo2} type="video/mp4" />
          </video>
        </SwiperSlide>
      )}
      <div className="carousel-overlay">
        {infoText && (
          <div className="yacht-info-text">
            <TitleParagraph
              title={infoText.title || ""}
              paragraph={infoText.subTitle || ""}
              titleColor="white"
              paragraphColor="white"
            />
            <div className="button-container">
              <Button buttonStyle="quintenary" onClick={infoText.buttonClick}>
                {infoText.buttonText}
              </Button>
              {secondaryButton && (
                <Button
                  buttonStyle="tertiary"
                  onClick={infoText.secondaryButtonClick}
                >
                  {infoText.secondaryButtonText}
                </Button>
              )}
            </div>
          </div>
        )}
        {showLogo && (
          <div className="logo-container">
            <img src={mainLogo} alt="Main Logo" />
          </div>
        )}
      </div>
    </Swiper>
  );
};

export default FeaturedCarousel;
